import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import 'slick-carousel';
import sal from 'sal.js';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.checkIE11();
    this.initSal();
    this.initHeader();
    this.initFilters();
    this.initFeaturedSlider();
    this.headerLinks();
    this.initTestimonials();
    this.smoothScroll();
    this.blogPosts();
    if ($('.js-map').length) {
      this._createMap();
    }
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-nav-mobile');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-search', event => this.showSearch(event));
    this.$doc.on('click', '.js-nav-mobile .menu-item a', event =>
      this.showSubMenu(event)
    );
  },
  checkIE11() {
    const uaString = navigator.userAgent;
    const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match && match[2] == 11){
      this.$html.addClass('ie11');
      this.initTestimonials();
      this.initFeaturedSlider();
      this.headerLinks();
      this.initFilters();
      if ($('.js-map').length) {
        this._createMap();
      }
    }
  },
  initSal() {
    sal({
      threshold: 0.1,
    });
  },
  blogPosts(){
    // remove duplicates
    // TODO: This method had been handled on PHP side and can be deleted
    /*var featuredIds = [];
    $.each($(".featured-posts--column article.post-item"), function( i, val ) {
      var elemId = "#" + $(this).attr("id");
      featuredIds.push(elemId);
    });
    $.each(featuredIds, function( i, val ) {
      $(".page-template-blog .featured-posts--row " + val).parent().remove();
    });*/

    // add random thumbnail
    $.each($(".post-item__thumb-random"), function( i, val ) {
      var randomNum = random([1, 2, 3]);
      $(this).addClass("post-item__thumb-random--" + randomNum);
    });

    function random(numbers) {
      return numbers[Math.floor(Math.random()*numbers.length)];
    }
  },
  smoothScroll(){
    $(document).on('click', 'a[href^="#"].contactForm', function (event) {
      event.preventDefault();
      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
    });
  },
  _createMap() {
    google.maps.event.addDomListener(window, 'load', this._createMap);
    var mapOptions = {
      zoom: 15,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      center: new google.maps.LatLng(lat1, lng1),
    };
    var map = document.querySelector('.js-map');
    var map = new google.maps.Map(map, mapOptions);
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat1, lng1),
      map: map,
      title: 'Informing Change'
    });
  },
  initTestimonials(){
    if ($('.js-testimonials').length) {
      $('.js-testimonials').slick({
        speed: 1200,
        easing: 'easeOutElastic',
        slidesToShow: 3,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 1,
        centerMode: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              centerMode: true,
              initialSlide: 1
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              initialSlide: 1
            }
          }
        ]
      });
    }
  },
  headerLinks(){
    if($("body").hasClass("publication-template-default")){
      var publications = $('a[href*="/publications/"]')[0];
      $(publications).parent().addClass("current_page_item current-menu-item");
      $(publications).attr("aria-current", "page");
      $(publications).closest(".menu-item-has-children").addClass("current-menu-ancestor current-menu-parent");
    }
    else if($("body").hasClass("project-template-default")){
      var projects = $('a[href*="/projects/"]')[0];
      $(projects).parent().parent().addClass("current_page_item current-menu-item");
      $(projects).attr("aria-current", "page");
      $(projects).closest(".menu-item-has-children").addClass("current-menu-ancestor current-menu-parent");
    }
    $(".current_page_parent").find("a").eq(0).addClass("u-color-green");
    $(".current-menu-parent").find("a").eq(0).addClass("u-color-green");
  },
  initFilters(){
    if($(".js-filter-btn").length){
      $(".js-filter-btn").on('click', function(){
        if($(this).hasClass("filter-active")){
          console.log('closeeeeee');
          $(this).siblings(".filters__select").hide();
          $(this).removeClass("filter-active");
          return;
        }
        else{
          $.each($(".js-filter-btn"), function( i, val ) {
            if($(this).hasClass('filter-active')){
              $(this).siblings(".filters__select").hide();
              $(this).removeClass("filter-active");
              return;
            }
            else{
              return;
            }
          });
          $(this).toggleClass("filter-active");
          $(this).siblings(".filters__select").toggle();
        }
      });
      $('.filters__select input[type="radio"]').on('click', function(){
        var selected = $(this).siblings("span").text();
        $(".filters__select").hide();
        $(this).parent().parent().siblings(".js-filter-btn").text(selected);
        $(this).parent().parent().siblings(".js-filter-btn").removeClass("filter-active")
      });
      $.each($('.filters__select input[type="radio"]'), function( i, val ) {
        if($(this).attr('checked')){
          var text = $(this).siblings("span").text();
          $(".filters__results div").append("<span>"+text+"</span>");
        }
      });
      $('body').click(function(evt){
        if(evt.target.className == "js-filter-btn")
          return;
        if($(evt.target).closest('.js-filter-btn').length)
          return;
        $(".filters__select").hide();
      });
    }
  },
  initFeaturedSlider(){
    if ($('.js-featured-projects').length) {
      $('.js-featured-projects').slick({
        speed: 1200,
        easing: 'easeOutElastic',
        slidesToShow: 3,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              initialSlide: 1
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              initialSlide: 2,
              autoplay: true,
              autoplaySpeed: 1800,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              initialSlide: 2,
              autoplay: true,
              autoplaySpeed: 1800,
              dots: true
            }
          }
        ]
      });
    }
  },
  initHeader(){
    $(".menu-item--search.js-search a").text("");
    $(".button--search").text("");
  },
  showSearch(event) {
    event.preventDefault();
    this.$header.toggleClass('has-active-search');
  },
  showSubMenu(event) {
    const el = $(event.currentTarget).parent();
    if (el.hasClass('menu-item-has-children')) {
      event.preventDefault();
      el.toggleClass('is-active');
    }
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  showMobileNav(event) {
    console.log('mobileee');
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    $("body").toggleClass("body--fixed");
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  }
};

Mangrove.init();
